/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.2.4 routes of Sendapostcardonline::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/add_company_to_collection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_company_to_collection_ecomm_analyze_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"add_company_to_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/add_creative(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_creative_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_creative"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/add_domain(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_domain_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_asset_placements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/bots/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bots(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_bots_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bots"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaigns_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_contact_infos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/creative_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_creative_asset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"creative_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/creative_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_creative_assets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"creative_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/encrypted_store(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_encrypted_store_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"encrypted_store"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/gigs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_gigs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"gigs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_key_value_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_key_values_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view/ads(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_ad_creatives_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[6,"ads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/add_page_snapshotter(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_add_page_snapshotter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_page_snapshotter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/add_person_to_company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_add_person_to_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"add_person_to_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/add_prospect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_add_prospect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"add_prospect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/add_tiktok_username(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_add_tiktok_username_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_tiktok_username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/analyze-csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_analyze_csv_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"analyze-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/clean_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_clean_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"clean_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/domain_enrich(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_domain_enrich_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"domain_enrich"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/domain_enrich_bulk(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_domain_enrich_bulk_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"domain_enrich_bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/download_csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_download_csv_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"download_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/enrich_company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_enrich_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"enrich_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/enrichment_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_enrichment_stats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"enrichment_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/export-leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_export_leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"export-leads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:snapshotter_id/gather_performance(.:format)
 * @param {any} snapshotter_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_gather_performance_path = __jsr.r({"snapshotter_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"snapshotter_id"],[2,[7,"/"],[2,[6,"gather_performance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/guess_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_guess_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"guess_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view/key_values(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_key_values_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[6,"key_values"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view/meta_enrichments(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_meta_enrichments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[6,"meta_enrichments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/remove_page_snapshotter(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_remove_page_snapshotter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_page_snapshotter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/remove_person(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_remove_person_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"remove_person"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:snapshotter_id/take_snapshot(.:format)
 * @param {any} snapshotter_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_take_snapshot_path = __jsr.r({"snapshotter_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"snapshotter_id"],[2,[7,"/"],[2,[6,"take_snapshot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/update_enrichment/:enrichment(.:format)
 * @param {any} enrichment
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_update_enrichment_path = __jsr.r({"enrichment":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"update_enrichment"],[2,[7,"/"],[2,[3,"enrichment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view/update_key_values(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_update_key_values_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[6,"update_key_values"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view/update_meta_enrichments(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_update_meta_enrichments_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[2,[7,"/"],[2,[6,"update_meta_enrichments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/upsert_domain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_upsert_domain_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[6,"upsert_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lead_enrichment/:id/view(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lead_enrichment_view_enrichment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lead_enrichment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_magic_sheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_magic_sheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/site_vars/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_site_var_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"site_vars"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admins_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/all_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_categories_ecomm_analyze_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"all_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blog/tweets/all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_tweets_ecomm_analyze_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"tweets"],[2,[7,"/"],[2,[6,"all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/analyze(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const analyze_ecomm_analyze_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"analyze"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/answer_with_context(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_answer_with_context_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"answer_with_context"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/books/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_book_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"books"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/books(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_books_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"books"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/person/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"person"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/person(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_person_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"person"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/temporary_data/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_temporary_datum_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"temporary_data"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/ask_question(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ask_question_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ask_question"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /asset_placements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id/attach_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attach_media_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attach_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/author/:name(.:format)
 * @param {any} name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const author_ecomm_analyze_blog_posts_path = __jsr.r({"name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"author"],[2,[7,"/"],[2,[3,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/tweets/auto_dm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_dm_birdword_tweets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"tweets"],[2,[7,"/"],[2,[6,"auto_dm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/masquerade/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_user_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /birdword/oauth_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"oauth_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/oauth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_oauth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"oauth"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_oauth2_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/oauth2_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_oauth2_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"oauth2_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/tweets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const birdword_tweets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"tweets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /book(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const book_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"book"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/bulk_add_domains(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_add_domains_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bulk_add_domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/:id/capture_landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capture_landing_pages_ecomm_analyze_ad_creative_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"capture_landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/categories(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categories_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categories_ecomm_analyze_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cheatcode(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cheatcode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cheatcode"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/clear_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_companies_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clear_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collections/analyze(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collections_analyze_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"analyze"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companies_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/google(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_google_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"google"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/landing_pages(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_landing_pages_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/meta(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_meta_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"meta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/other_social_media(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_other_social_media_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"other_social_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/overview(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_overview_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/technology(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_technology_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"technology"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/compare/:company_slug/tiktok(.:format)
 * @param {any} id
 * @param {any} company_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compare_tiktok_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"company_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"company_slug"],[2,[7,"/"],[2,[6,"tiktok"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /landing_pages/comparison(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comparison_ecomm_analyze_landing_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[6,"comparison"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /landing_pages/:id/comparison_row(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comparison_row_ecomm_analyze_landing_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comparison_row"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/competitive-analysis(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const competitive_analysis_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"competitive-analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/competitive_rank(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const competitive_rank_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"competitive_rank"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id/convert(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"convert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/tweets/convert(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const convert_birdword_tweets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"tweets"],[2,[7,"/"],[2,[6,"convert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/create_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_search_gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"create_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/demo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const demo_admin_asset_placements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[6,"demo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/destroy_attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_attachment_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/destroy_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_search_gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"destroy_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/download_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_csv_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/download_matching_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_matching_companies_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_matching_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/:id/draft(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_ecomm_analyze_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ea_pixel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ea_pixel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ea_pixel"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ea_track(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ea_track_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ea_track"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-brand-analyzer/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tiktok-brand-analyzer"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ad_creative_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ad_creatives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_admin_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_admin_saved_searches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agencies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_agencies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agencies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /agencies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_agency_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"agencies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /analyze(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_analyze_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"analyze"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/autofill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_autofill_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"autofill"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/autofill_freetext(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_autofill_freetext_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"autofill_freetext"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/brand_analyzer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_brand_analyzer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"brand_analyzer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_ecomm_apis_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/enrich_company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_enrich_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"enrich_company"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/find_amazon_merchant_id(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_find_amazon_merchant_id_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"find_amazon_merchant_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/find_ecomm_website_from_product(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_find_ecomm_website_from_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"find_ecomm_website_from_product"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/find_leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_find_leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"find_leads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/job_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_job_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"job_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/shopify_reviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_shopify_reviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"shopify_reviews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/verify_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_api_verify_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"verify_email"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /attribution(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_attribution_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"attribution"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blog/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /checkout_sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_checkout_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout_sessions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /competitors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_competitors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"competitors"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer_portal_sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_customer_portal_sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_portal_sessions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-ai-data-wizard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_ai_data_wizard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-ai-data-wizard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_apis_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm-data-autofill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_data_autofill_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-data-autofill"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-lead-finder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_lead_finder_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-lead-finder"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-list"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-lists/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_list_preview_path_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm-lists"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm-lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-lists"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-personalized-first-lines-generator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_personalized_first_lines_generator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-personalized-first-lines-generator"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecomm-similar-store-finder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ecomm_similar_store_finder_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm-similar-store-finder"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /external_signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_external_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"external_signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /lander(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_lander_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lander"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /landing_pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_landing_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /landing_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_landing_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /links/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_link_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /links(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_links_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"links"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /o/:tdid(.:format)
 * @param {any} tdid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_links_redirect_path = __jsr.r({"tdid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"o"],[2,[7,"/"],[2,[3,"tdid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_modal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /opt-out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_opt_out_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"opt-out"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /opt-out-submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_opt_out_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"opt-out-submit"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rl/ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rl"],[2,[7,"/"],[2,[6,"ping"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /privacy_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /submit_lander_lead(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_submit_lander_lead_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"submit_lander_lead"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /submit_lead(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_submit_lead_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"submit_lead"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /technologies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_technologies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"technologies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /technologies/:identifier(.:format)
 * @param {any} identifier
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_technology_path = __jsr.r({"identifier":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"technologies"],[2,[7,"/"],[2,[3,"identifier"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /technology-finder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_technology_finder_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"technology-finder"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-brand-analyzer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_brand_analyzer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-brand-analyzer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-brand-analyzer-submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_brand_analyzer_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-brand-analyzer-submit"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-brand-leaderboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_brand_leaderboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-brand-leaderboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-hashtag-analyzer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_hashtag_analyzer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-hashtag-analyzer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-hashtag-analyzer-submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_hashtag_analyzer_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-hashtag-analyzer-submit"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-hashtag-generator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_hashtag_generator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-hashtag-generator"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tiktok-hashtag-generator-submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tiktok_hashtag_generator_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tiktok-hashtag-generator-submit"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tools/status/:jid(.:format)
 * @param {any} jid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tool_job_status_path = __jsr.r({"jid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tools"],[2,[7,"/"],[2,[6,"status"],[2,[7,"/"],[2,[3,"jid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tools"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /top_landing_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_top_landing_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"top_landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_tos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /twitter-comment-scraper(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_twitter_comment_scraper_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"twitter-comment-scraper"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecomm_analyze_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admins/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bots/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_bot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_magic_sheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_message_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/person/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"person"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_api_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /landing_pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_landing_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /links/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecomm_analyze_link_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gig_workflows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_gig_guru_gig_workflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values/:id/editor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const editor_admin_key_value_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"editor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/email_preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_preview_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"email_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /email_verifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_verification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"email_verifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /email_verifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_verifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_verifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/enrich_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enrich_companies_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enrich_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/enrich_connections(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enrich_connections_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enrich_connections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/enrich_landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enrich_landing_pages_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enrich_landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_ecomm_analyze_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/facebook_ads_carousel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const facebook_ads_carousel_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"facebook_ads_carousel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/facebook_ads(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const facebook_ads_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"facebook_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/facebook_ads(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const facebook_ads_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"facebook_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/facebook_ads_paginated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const facebook_ads_paginated_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"facebook_ads_paginated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/facebook_ads_paginated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const facebook_ads_paginated_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"facebook_ads_paginated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gig_workflows/fields_for_action_type(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_for_action_type_gig_guru_gig_workflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[2,[7,"/"],[2,[6,"fields_for_action_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/find_leads_for_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_leads_for_companies_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"find_leads_for_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/follow_collection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_collection_ecomm_analyze_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"follow_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/follow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"follow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/follow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"follow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/followed_companies_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const followed_companies_summary_ecomm_analyze_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"followed_companies_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/generate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_admin_asset_placements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[6,"generate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values/:id/generate_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_data_admin_key_value_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/get_answer/:question_slug(.:format)
 * @param {any} id
 * @param {any} question_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_answer_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"question_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_answer"],[2,[7,"/"],[2,[3,"question_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis/:id/get_match_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_match_results_ecomm_analyze_api_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_match_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /gig_workflows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gig_guru_gig_workflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /gig_workflows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gig_guru_gig_workflows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /gigs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gig_guru_gigs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gigs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gig_guru_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id/growth_summary(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const growth_summary_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"growth_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/person/hello_world(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hello_world_api_person_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"person"],[2,[7,"/"],[2,[6,"hello_world"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/job_status/:job_id(.:format)
 * @param {any} id
 * @param {any} job_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const job_status_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"job_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"job_status"],[2,[7,"/"],[2,[3,"job_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/job_statuses(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const job_statuses_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"job_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/competitors(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_competitors_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"competitors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/detailed_competitive_analysis(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_detailed_competitive_analysis_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"detailed_competitive_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/google-ads(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_google_ads_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"google-ads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/page_block/:name(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {any} name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_page_block_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"page_block"],[2,[7,"/"],[2,[3,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/popup/:name(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {any} name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_popup_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"popup"],[2,[7,"/"],[2,[3,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name(/:prospect_id)/record_event(.:format)
 * @param {any} client_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_record_event_path = __jsr.r({"client_name":{"r":true},"prospect_id":{},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[1,[2,[7,"/"],[3,"prospect_id"]]],[2,[7,"/"],[2,[6,"record_event"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/:prospect_id/record_form(.:format)
 * @param {any} client_name
 * @param {any} prospect_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_record_form_path = __jsr.r({"client_name":{"r":true},"prospect_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[6,"record_form"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/landing_page_stats(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_stats_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_page_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name/tabbed_competitive_analysis(.:format)
 * @param {any} client_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_tabbed_competitive_analysis_path = __jsr.r({"client_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[7,"/"],[2,[6,"tabbed_competitive_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:saved_search_id/:prospect_id/:campaign_id/unsubscribe(.:format)
 * @param {any} saved_search_id
 * @param {any} prospect_id
 * @param {any} campaign_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_page_unsubscribe_path = __jsr.r({"saved_search_id":{"r":true},"prospect_id":{"r":true},"campaign_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"saved_search_id"],[2,[7,"/"],[2,[3,"prospect_id"],[2,[7,"/"],[2,[3,"campaign_id"],[2,[7,"/"],[2,[6,"unsubscribe"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/landing_pages/:slug(.:format)
 * @param {any} id
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_for_company_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/landing_pages_modal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_modal_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/landing_pages_overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_overview_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/landing_pages_paginated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_paginated_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"landing_pages_paginated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/list_preview_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_preview_download_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_preview_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/list_preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_preview_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/list_preview_sample(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_preview_sample_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"list_preview_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/marketing_overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const marketing_overview_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"marketing_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/meta_ads_list_paginated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meta_ads_list_paginated_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"meta_ads_list_paginated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/milvus_index(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const milvus_index_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"milvus_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admins/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_asset_placement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_blog_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bots/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_bot_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bots"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_campaign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_contact_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_key_value_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_magic_sheet_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_message_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/person/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_person_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"person"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_birdword_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_collection_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_admin_collection_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_api_ecomm_api_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_blog_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_ecomm_api_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_job_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /landing_pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_landing_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"landing_pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /links/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecomm_analyze_link_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /email_verifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_email_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_verifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /gig_workflows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_gig_guru_gig_workflow_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /jobs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_job_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth2callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth2callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth2callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /oauth/dropbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_dropbox_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"dropbox"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/dropbox/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_dropbox_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"dropbox"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/google(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_google_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"google"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/reddit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_reddit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"reddit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /oauth/reddit/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_reddit_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"reddit"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pixel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pixel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pixel"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pixels(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pixels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pixels"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id/postcards(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postcards_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"postcards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_templates/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_message_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/preview_download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_download_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/preview_prospect_ids(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_prospect_ids_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_prospect_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/preview_submit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_submit_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_submit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/ecomm_apis/:id/process_domain(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_domain_ecomm_analyze_api_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"process_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/process_match(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_match_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"process_match"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets/:id/process_now(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_now_admin_magic_sheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"process_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/iframe/:client_name(/:prospect_id)(.:format)
 * @param {any} client_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prospect_iframe_landing_page_path = __jsr.r({"client_name":{"r":true},"prospect_id":{},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[6,"iframe"],[2,[7,"/"],[2,[3,"client_name"],[2,[1,[2,[7,"/"],[3,"prospect_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /l/:client_name(/:prospect_id)(.:format)
 * @param {any} client_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prospect_landing_page_path = __jsr.r({"client_name":{"r":true},"prospect_id":{},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[3,"client_name"],[2,[1,[2,[7,"/"],[3,"prospect_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id/purge_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purge_images_admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"purge_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/push_to_instantly(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_to_instantly_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"push_to_instantly"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/random_landing_page(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const random_landing_page_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"random_landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/realtime_revenue(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const realtime_revenue_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"realtime_revenue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gigs/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recent_gig_guru_gigs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gigs"],[2,[7,"/"],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /redirect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const redirect_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"redirect"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/magic_sheets/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_admin_magic_sheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"magic_sheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/refresh_creatives(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_creatives_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh_creatives"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/refresh(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_ecomm_analyze_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/reload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reload_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/remove_company(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_company_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/reprocess_matching_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reprocess_matching_companies_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reprocess_matching_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/request_info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_info_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/bots/:id/run_bot(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const run_bot_admin_bot_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"run_bot"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/:id/save(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_ecomm_analyze_ad_creative_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/saved_searches(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saved_searches_ecomm_analyze_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/saver(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saver_ecomm_analyze_ad_creatives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[6,"saver"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/saver_submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saver_submit_ecomm_analyze_ad_creatives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[6,"saver_submit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id/schedule(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/schedule_bulk(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_bulk_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"schedule_bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecomm_apis/:id/schedule_export(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_export_ecomm_analyze_ecomm_api_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecomm_apis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"schedule_export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/scrape(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scrape_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"scrape"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ad_creatives/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_ecomm_analyze_ad_creatives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ad_creatives"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id/send_postcards(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_postcards_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_postcards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/settings(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/setup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setup_gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"setup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_admin_asset_placement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /email_verifications/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const single_email_verifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_verifications"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /slack/commands/bot_command(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack_commands_bot_command_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"commands"],[2,[7,"/"],[2,[6,"bot_command"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /slack/commands/magic_sheet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack_commands_magic_sheet_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"commands"],[2,[7,"/"],[2,[6,"magic_sheet"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /slack/commands/magic_sheet_run(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slack_commands_magic_sheet_run_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"commands"],[2,[7,"/"],[2,[6,"magic_sheet_run"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id/smart_reply(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const smart_reply_birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"smart_reply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/soft_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const soft_companies_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"soft_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_ecomm_analyze_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /email_verifications/:id/status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_email_verification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"email_verifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /jobs/:id/status_notification(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_notification_ecomm_analyze_job_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"status_notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /l/submit_lead/:client_name(.:format)
 * @param {any} client_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_landing_page_lead_path = __jsr.r({"client_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"l"],[2,[7,"/"],[2,[6,"submit_lead"],[2,[7,"/"],[2,[3,"client_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agencies/submit_lead(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_lead_ecomm_analyze_agencies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agencies"],[2,[7,"/"],[2,[6,"submit_lead"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_google(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_google_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_google"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_google(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_google_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_google"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_landing_pages_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_landing_pages(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_landing_pages_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_landing_pages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_meta(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_meta_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_meta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_meta(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_meta_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_meta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_other_social_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_other_social_media_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_other_social_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_other_social_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_other_social_media_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_other_social_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_overview_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_overview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_overview_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_technology(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_technology_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_technology"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_technology(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_technology_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_technology"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_tiktok(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_tiktok_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_tiktok"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/tab_tiktok(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tab_tiktok_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tab_tiktok"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/technologies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const technologies_ecomm_analyze_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"technologies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /gig_workflows/:id/test(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_gig_guru_gig_workflow_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"gig_workflows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/test_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const test_search_gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"test_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/testimonial_matcher(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const testimonial_matcher_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"testimonial_matcher"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/testimonial_matcher_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const testimonial_matcher_results_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"testimonial_matcher_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/tiktok-opportunities(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiktok_opportunities_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tiktok-opportunities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/tiktok_posts_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiktok_posts_all_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tiktok_posts_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/tiktok_posts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiktok_posts_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tiktok_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/tiktok_posts_paginated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiktok_posts_paginated_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tiktok_posts_paginated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pixels/track(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const track_pixels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pixels"],[2,[7,"/"],[2,[6,"track"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blog/tweets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tweet_ecomm_analyze_blog_posts_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"tweets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /birdword/lists/:id/tweets(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tweets_birdword_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"birdword"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tweets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blog/tweets/by_tag/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tweets_by_tag_ecomm_analyze_blog_posts_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"tweets"],[2,[7,"/"],[2,[6,"by_tag"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/unfollow_collection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unfollow_collection_ecomm_analyze_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unfollow_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /collections/:id/unfollow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unfollow_ecomm_analyze_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unfollow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/unfollow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unfollow_ecomm_analyze_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unfollow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/saved_searches/:id/unmatch_company(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unmatch_company_ecomm_analyze_admin_saved_search_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"saved_searches"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unmatch_company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/update_categories(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_categories_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/update_embedding(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_embedding_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_embedding"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/update_modal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_modal_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/update_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_settings_gig_guru_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaigns/:id/upload_address_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_address_csv_admin_campaign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaigns"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_address_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/contact_infos/:id/upload_attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_attachment_admin_contact_info_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/books/upload_image(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_image_api_books_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"books"],[2,[7,"/"],[2,[6,"upload_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/key_values/upsert(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upsert_admin_key_values_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"key_values"],[2,[7,"/"],[2,[6,"upsert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/masquerade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"masquerade"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agencies/verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verification_ecomm_analyze_agencies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agencies"],[2,[7,"/"],[2,[6,"verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/view_bulk_add_domains(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const view_bulk_add_domains_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view_bulk_add_domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/view_soft_companies(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const view_soft_companies_ecomm_analyze_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"view_soft_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/direct_mailer/listen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_direct_mailer_listen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"direct_mailer"],[2,[7,"/"],[2,[6,"listen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/listen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_listen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"listen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/slack/interactivity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_slack_interactivity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"slack"],[2,[7,"/"],[2,[6,"interactivity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/stripe/listen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_stripe_listen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"stripe"],[2,[7,"/"],[2,[6,"listen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/the_companies_api_listen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_the_companies_api_listen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"the_companies_api_listen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/asset_placements/wizard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wizard_admin_asset_placements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"asset_placements"],[2,[7,"/"],[2,[6,"wizard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);


